"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _keys = _interopRequireDefault(require("@babel/runtime-corejs2/core-js/object/keys"));

var _iterator = _interopRequireDefault(require("@babel/runtime-corejs2/core-js/symbol/iterator"));

var _symbol = _interopRequireDefault(require("@babel/runtime-corejs2/core-js/symbol"));

var _promise = _interopRequireDefault(require("@babel/runtime-corejs2/core-js/promise"));

var _create = _interopRequireDefault(require("@babel/runtime-corejs2/core-js/object/create"));

var _setPrototypeOf = _interopRequireDefault(require("@babel/runtime-corejs2/core-js/object/set-prototype-of"));

var _crc = require("../utils/crc32");

var _api = require("../api");

var _base = _interopRequireDefault(require("./base"));

var __extends = void 0 && (void 0).__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = _setPrototypeOf["default"] || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? (0, _create["default"])(b) : (__.prototype = b.prototype, new __());
  };
}();

var __awaiter = void 0 && (void 0).__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = _promise["default"]))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = void 0 && (void 0).__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof _symbol["default"] === "function" && (g[_iterator["default"]] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

var Direct =
/** @class */
function (_super) {
  __extends(Direct, _super);

  function Direct() {
    return _super !== null && _super.apply(this, arguments) || this;
  }

  Direct.prototype.run = function () {
    return __awaiter(this, void 0, void 0, function () {
      var formData, crcSign, customVars_1, result;

      var _this = this;

      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            this.logger.info('start run Direct.');
            formData = new FormData();
            formData.append('file', this.file);
            formData.append('token', this.token);

            if (this.key != null) {
              formData.append('key', this.key);
            }

            formData.append('fname', this.putExtra.fname);
            if (!this.config.checkByServer) return [3
            /*break*/
            , 2];
            return [4
            /*yield*/
            , _crc.CRC32.file(this.file)];

          case 1:
            crcSign = _a.sent();
            formData.append('crc32', crcSign.toString());
            _a.label = 2;

          case 2:
            if (this.putExtra.customVars) {
              this.logger.info('init customVars.');
              customVars_1 = this.putExtra.customVars;
              (0, _keys["default"])(customVars_1).forEach(function (key) {
                return formData.append(key, customVars_1[key].toString());
              });
              this.logger.info('customVars inited.');
            }

            this.logger.info('formData inited.');
            return [4
            /*yield*/
            , (0, _api.direct)(this.uploadHost.getUrl(), formData, {
              onProgress: function onProgress(data) {
                _this.updateDirectProgress(data.loaded, data.total);
              },
              onCreate: function onCreate(xhr) {
                return _this.addXhr(xhr);
              }
            })];

          case 3:
            result = _a.sent();
            this.logger.info('Direct progress finish.');
            this.finishDirectProgress();
            return [2
            /*return*/
            , result];
        }
      });
    });
  };

  Direct.prototype.updateDirectProgress = function (loaded, total) {
    // 当请求未完成时可能进度会达到100，所以total + 1来防止这种情况出现
    this.progress = {
      total: this.getProgressInfoItem(loaded, total + 1)
    };
    this.onData(this.progress);
  };

  Direct.prototype.finishDirectProgress = function () {
    // 在某些浏览器环境下，xhr 的 progress 事件无法被触发，progress 为 null，这里 fake 下
    if (!this.progress) {
      this.logger.warn('progress is null.');
      this.progress = {
        total: this.getProgressInfoItem(this.file.size, this.file.size)
      };
      this.onData(this.progress);
      return;
    }

    var total = this.progress.total;
    this.progress = {
      total: this.getProgressInfoItem(total.loaded + 1, total.size)
    };
    this.onData(this.progress);
  };

  return Direct;
}(_base["default"]);

var _default = Direct;
exports["default"] = _default;